






































































import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import moment from 'moment'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import CBookingDatePicker from '@/calendesk/sections/section/booking/components/CBookingDatePicker.vue'
import { mapActions } from 'vuex'
import parseRawBookingTimeSlots from '@/calendesk/tools/parseRawBookingTimeSlots'
import BookingTimeSlot from '@/calendesk/models/BookingTimeSlot'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import BookingRescheduleRequestData from '@/calendesk/models/DTO/Request/BookingRescheduleRequestData'

export default mixins(DraftElement).extend({
  components: { CBookingDatePicker },
  props: {
    booking: {
      type: Booking,
      required: true
    }
  },
  data () {
    return {
      minDate: new Date().toISOString().substr(0, 10),
      selectedDate: moment().format(DateTimeFormats.FULL) as string,
      selectedTime: null as string | null,
      availableHours: [] as Array<BookingTimeSlot>,
      calendarEvents: new Set() as Set<string>,
      calendarEventsArray: [] as Array<string>,
      isFetchingTimeSlots: false,
      isSaving: false
    }
  },
  watch: {
    selectedDate () {
      this.reloadEventsDebounce()
    }
  },
  computed: {
    firstAvailableDate (): string | null {
      const [first] = this.calendarEvents
      return first
    }
  },
  created () {
    this.reloadEvents()
  },
  methods: {
    ...mapActions({
      fetchAvailableBookingSlots: 'booking/fetchAvailableBookingSlots',
      rescheduleBooking: 'booking/reschedule',
      setReloadAllBookings: 'booking/setReloadAllBookings'
    }),
    setDate (date: string) {
      this.selectedDate = date
    },
    save () {
      this.isSaving = true
      const data = new BookingRescheduleRequestData(this.booking.control, this.selectedDate, this.selectedTime as string)
      this.rescheduleBooking(data).then((booking: Booking) => {
        this.setReloadAllBookings(true)
        successNotification('booking_rescheduled')
        this.close()
        this.reload(booking)
      }).catch((error) => {
        errorNotification('booking_rescheduled_fail', error, false)
      }).finally(() => {
        this.isSaving = false
      })
    },
    close () {
      this.$emit('close')
    },
    reload (booking: Booking) {
      this.$emit('reload', booking)
    },
    reloadEventsDebounce () {
      this.debounce(this.reloadEvents, 400)()
    },
    reloadEvents () {
      if (this.selectedDate && this.booking.employeeId && this.booking.serviceId) {
        this.isFetchingTimeSlots = true
        this.selectedTime = null

        this.fetchAvailableBookingSlots({
          used_slots: 1,
          number_of_days: 180,
          service_id: this.booking.serviceId,
          employee_ids: this.booking.employeeId,
          start_date: this.selectedDate,
          service_type_id: this.booking.serviceTypeId || null,
          customer_time_zone: this.booking.customerTimeZone,
          location_id: this.booking?.location?.locationId
        }).then((response: Record<string, any>) => {
          this.assignResponse(response)
        }).catch((error) => {
          this.$log.info('RescheduleCalendar - fetchAvailableBookingSlots@catch', error)
        }).finally(() => {
          this.isFetchingTimeSlots = false
        })
      }
    },
    assignResponse (response: Record<string, any>): Array<BookingTimeSlot> {
      if (this.booking && this.booking.employeeId && this.booking.service) {
        if (response[this.booking.employeeId] && response[this.booking.employeeId][this.selectedDate]) {
          for (const date of Object.keys(response[this.booking.employeeId])) {
            if (response[this.booking.employeeId][date].length > 0) {
              this.calendarEvents.add(date)
            }
          }

          this.calendarEventsArray = Array.from(this.calendarEvents)
          this.availableHours = parseRawBookingTimeSlots(response[this.booking.employeeId][this.selectedDate], this.booking.service.maxPeople)
        }
      }

      return []
    },
    getItemText (item: BookingTimeSlot) {
      const from = (this.$options.filters as any).timeNotation(item.time)
      let to = this.$moment(this.selectedDate + ' ' + item.time)
        .add(this.booking.getDuration(), 'minutes')
        .format(DateTimeFormats.TIME)
      to = (this.$options.filters as any).timeNotation(to)

      return `${from} - ${to}`
    }
  }
})

