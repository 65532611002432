

















































































































import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import CustomField from '@/calendesk/models/DTO/Response/CustomField'
import BookingActions from '@/views/components/mixins/BookingActions'
import AddToCalendarButton from '@/components/AddToCalendarButton.vue'
import LocationItem from '@/components/LocationItem.vue'
import { stripSurroundingPTags } from '@/calendesk/filters/stripSurroundingPTags'

export default mixins(DialogMixins).extend({
  name: 'BookingFormConfirmationBookingsView',
  components: { LocationItem, AddToCalendarButton },
  mixins: [BookingActions],
  props: {
    bookings: {
      type: Array,
      default: () => Array<Booking>()
    }
  },
  computed: {
    firstBooking (): Booking | null {
      if (this.bookings && this.bookings.length > 0) {
        return this.bookings[0] as Booking
      }

      return null
    },
    customFields (): Array<CustomField> | null {
      if (this.firstBooking && this.firstBooking.customFields) {
        return this.firstBooking.customFields
      }

      return null
    },
    eventsSumPrice (): number {
      let price = 0

      if (this.bookings) {
        this.bookings.forEach((booking: any) => {
          if (!booking.paid) {
            price += (booking as Booking).getPrice()
          }
        })
      }

      return price
    }
  },
  methods: {
    stripSurroundingPTags
  }
})
