



























import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import { mapGetters } from 'vuex'

export default mixins(DraftElement).extend({
  props: {
    booking: {
      type: Booking,
      required: true
    }
  },
  data () {
    return {
      calendars: [
        {
          type: 'google',
          title: 'Google Calendar',
          icon: require('@/assets/calendars/google.png')
        },
        {
          type: 'ical',
          title: 'Apple',
          icon: require('@/assets/calendars/apple.png')
        },
        {
          type: 'outlook',
          title: 'Outlook.com',
          icon: require('@/assets/calendars/outlook.png')
        },
        {
          type: 'microsoft365',
          title: 'Microsoft365',
          icon: require('@/assets/calendars/microsoft365.png')
        },
        {
          type: 'yahoo',
          title: 'Yahoo',
          icon: require('@/assets/calendars/yahoo.png')
        },
        {
          type: 'ical',
          title: 'iCal',
          icon: require('@/assets/calendars/ical.png')
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getTenant: 'setup/getTenant'
    })
  },
  methods: {
    openCalendarLink (type: string) {
      const url = `${process.env.VUE_APP_API_URL}generate/calendar/${type}/${this.booking.control}?tenant=${this.getTenant}`
      window.open(url, '_blank', 'noopener')
    }
  }
})
