export default class BookingRescheduleRequestData {
  public control: string
  public start_date: string
  public start_time: string

  constructor (control: string, start_date: string, start_time: string) {
    this.control = control
    this.start_date = start_date
    this.start_time = start_time
  }
}
