




























































































































import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import mixins from 'vue-typed-mixins'
import Booking from '@/calendesk/models/DTO/Response/Booking'
import BookingFormConfirmationBookingsView
  from '@/components/dialogs/components/BookingFormConfirmationDialog/components/BookingFormConfirmationBookingsView.vue'
import PaymentActions from '@/views/components/mixins/PaymentActions'
import Section from '@/calendesk/models/DTO/Response/Section'
import DialogSize from '@/calendesk/models/DialogSize'
import ConfirmDialog from '@/calendesk/models/ConfirmDialog'
import { ConfirmDialogTypes } from '@/components/dialogs/ConfirmDialogTypes'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import { mapActions } from 'vuex'
import plural from '@/calendesk/filters/plural'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import RescheduleCalendar
  from '@/components/dialogs/components/BookingFormConfirmationDialog/components/RescheduleCalendar.vue'

export default mixins(DialogMixins, PaymentActions).extend({
  name: 'BookingFormConfirmationDialog',
  components: { RescheduleCalendar, BookingFormConfirmationBookingsView },
  data () {
    return {
      isLoading: false,
      bookings: [] as Array<Booking>,
      section: null as Section | null,
      promoCode: null,
      info: null as string | null,
      showPromoCode: false,
      showRescheduleCalendar: false,
      hasAnyBookingThatCanBePaidWithSubscription: false
    }
  },
  computed: {
    bookingIds (): number[] {
      return this.bookings.map(booking => booking.id)
    },
    bookingsUnpaid (): boolean {
      if (this.bookings) {
        return this.bookings.some((booking: Booking) => !booking.paid)
      }

      return false
    },
    firstBooking (): Booking | null {
      if (this.bookings && this.bookings.length > 0) {
        return this.bookings[0]
      }

      return null
    },
    canCancelBooking (): boolean {
      return !!this.firstBooking &&
        this.bookings.length === 1 &&
        this.firstBooking.canBeCanceled()
    },
    canRescheduleBooking (): boolean {
      return !!this.firstBooking &&
        this.bookings.length === 1 &&
        this.firstBooking.canBeRescheduled()
    },
    hasProducts (): boolean {
      if (this.bookings) {
        const withProducts = this.bookings.filter(booking => booking.simpleStoreProducts && booking.simpleStoreProducts.length > 0)

        return withProducts.length > 0
      }

      return false
    }
  },
  created () {
    this.bookings = this.dialog.data.bookings
    this.section = this.dialog.data.section
    this.info = this.dialog.data.info

    this.checkBookingsIfCanBePaidWithSubscription()
  },
  methods: {
    ...mapActions({
      cancelBooking: 'booking/cancel',
      setReloadAllBookings: 'booking/setReloadAllBookings',
      canBePaidWithSubscription: 'booking/canBePaidWithSubscription'
    }),
    async checkBookingsIfCanBePaidWithSubscription () {
      if (!this.isUserLogged) {
        this.isLoading = true
        for (const booking of this.bookings) {
          const result = await this.canBePaidWithSubscription(booking.id)
          if (result && result.can_pay) {
            this.hasAnyBookingThatCanBePaidWithSubscription = true
            break
          }
        }
        this.isLoading = false
      }
    },
    payWithSubscription () {
      if (this.isUserLogged) {
        this.openSubscriptionsDialog()
      } else {
        this.setAfterLoginAction(this.openSubscriptionsDialog)
        this.openLoginDialog()
      }
    },
    openSubscriptionsDialog () {
      this.openConfirmDialog(
        new ConfirmDialog(true, ConfirmDialogTypes.PAY_WITH_SUBSCRIPTION, DialogSize.SMALL, { bookingIds: this.bookingIds }, (bookingIds: number[]) => {
          if (bookingIds && bookingIds.length > 0) {
            bookingIds.forEach((id) => {
              const booking = this.bookings.find((booking) => booking.id === id)

              if (booking) {
                booking.paid = true
                booking.status = BookingStatus.APPROVED
              }
            })

            const message = `${this.$trans('bookings_paid_by_subscription_success_message')} ${plural(bookingIds.length, 'booking', true)}`
            successNotification(message, true)
          } else {
            errorNotification('bookings_not_paid_by_subscription_error_message', null, false)
          }
        })
      )
    },
    goToBookings () {
      this.closeDialog()
      this.redirectToBookings()
    },
    cancelClicked () {
      if (this.bookings && this.bookings.length > 0) {
        this.openConfirmDialog(
          new ConfirmDialog(true, ConfirmDialogTypes.COMMON, DialogSize.SMALL, {}, this.cancelConfirmAction)
        )
      }
    },
    rescheduleClicked () {
      if (this.bookings && this.bookings.length > 0) {
        this.showRescheduleCalendar = true
      }
    },
    cancelConfirmAction () {
      if (this.bookings && this.bookings.length > 0) {
        this.setConfirmDialogLoader(true)

        this.cancelBooking((this.bookings[0] as Booking).control).then(async () => {
          if (typeof (window as any).calendeskBookingsCanceled === 'function') {
            await (window as any).calendeskBookingsCanceled(this.bookings[0])
          }

          this.setReloadAllBookings(true)
          successNotification('booking_canceled')
        }).catch((error) => {
          errorNotification('booking_canceled_fail', error, false)
        }).finally(() => {
          this.closeDialog()
          this.closeConfirmDialog()
          this.setConfirmDialogLoader(false)
        })
      }
    },
    reloadBooking (booking: Booking) {
      if (booking && this.bookings && this.bookings.length === 1) {
        this.bookings = [booking]
      }
    }
  }
})
